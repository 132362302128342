<template>
  <div
    class="newsletter"
    :class="{
      ['bg-color--' + backgroundColor]: Boolean(backgroundColor),
      [`mp--${mobilePadding}`]: Boolean(mobilePadding),
      [`mm--${mobileMargin}`]: Boolean(mobileMargin),
      [`dp--${desktopPadding}`]: Boolean(desktopPadding),
      [`dm--${desktopMargin}`]: Boolean(desktopMargin),
      [`di ${newsletterIndent}`]: Boolean(newsletterIndent),
    }"
    :style="[backgroundColorCss, foregroundColorCss]"
  >
    <div class="content grid dc--1 dg--xlarge">
      <div class="text-wrap">
        <div
          v-if="headline"
          class="headline"
          :class="headlineSize"
          v-html="nl2br(headline.html)"
        />
        <template v-else>
          <div class="headline h--medium show-on-mobile">
            {{ $t('Newsletter_Headline') }}
          </div>
          <div class="headline h--x-large di--4 show-on-desktop">
            {{ $t('Newsletter_Headline') }}
          </div>
        </template>

        <div v-if="text" class="text" v-html="text.html" />
      </div>
      <div class="form">
        <div class="input-text-underline">
          <input
            v-model="email"
            class="newsletter__input-text"
            type="email"
            :placeholder="getPlaceholder"
            @keyup.enter="subscribe"
          />
          <button
            :aria-label="getButtonLabel"
            :disabled="loading"
            @click="subscribe"
          >
            <ArrowLongIcon class="newsletter__input__icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'
import ArrowLongIcon from '~/static/images/arrow-long.svg'

export default {
  name: 'Newsletter',
  components: {
    ArrowLongIcon,
  },
  props: {
    headline: {
      type: Object,
      required: false,
      default: null,
    },
    headlineSize: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: Object,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'beige',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    buttonLabel: {
      type: String,
      required: false,
      default: '',
    },
    list: {
      type: String,
      required: true,
    },
    foregroundColor: {
      type: String,
      default: null,
    },
    mobilePadding: {
      type: String,
      default: null,
    },
    mobileMargin: {
      type: String,
      default: null,
    },
    desktopPadding: {
      type: String,
      default: null,
    },
    desktopMargin: {
      type: String,
      default: null,
    },
    newsletterIndent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  computed: {
    foregroundColorCss() {
      return {
        '--foreground-color': `var(--${this.foregroundColor})`,
        '--underline-color':
          this.foregroundColor !== 'white' &&
          this.foregroundColor !== 'black' &&
          this.foregroundColor !== null
            ? `var(--${this.foregroundColor})`
            : 'var(--blue)',
      }
    },
    backgroundColorCss() {
      const backgroundColor = color(this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    },
    getButtonLabel() {
      return this.buttonLabel
        ? this.buttonLabel
        : this.$t('Newsletter_Subscribe')
    },
    getPlaceholder() {
      return this.placeholder
        ? this.placeholder
        : this.$t('Newsletter_Your_Email')
    },
  },
  methods: {
    subscribe() {
      const successSubscription = () => {
        this.$notify({
          title: this.$t('Newsletter_Signup_SuccessHeader'),
          text: this.$t('Newsletter_Signup_SuccessMsg'),
          type: 'success',
        })
      }

      const handleSubscriptionError = (error) => {
        console.error('Newsletter subscription error: ', error)
        this.$notify({
          title: this.$t('Newsletter_Signup_ErrorHeader'),
          text: this.$t('Newsletter_Signup_ErrorMsg'),
          type: 'error',
        })
      }

      const afterSubscription = () => {
        this.loading = false
        this.email = ''
      }

      const register = async (token) => {
        return await this.$backendApi.post(
          `klaviyo/list/${this.list}/subscribe?recaptchaToken=${token}`,
          {
            email: this.email,
          }
        )
      }

      const executeRecaptchaTestAndRegister = async () => {
        try {
          const token = await window.grecaptcha.execute(
            this.$config.recaptchaSiteKey,
            { action: 'submit' }
          )
          await register(token)
          successSubscription()
        } catch (error) {
          handleSubscriptionError(error)
        } finally {
          afterSubscription()
        }
      }

      this.loading = true
      window.grecaptcha.ready(executeRecaptchaTestAndRegister)
    },
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
  },
}
</script>

<style lang="scss" scoped>
.newsletter {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  color: var(--foreground-color, $black);

  .input-text-underline {
    border-bottom-color: var(--underline-color);
  }

  .newsletter__input-text {
    color: var(--foreground-color);

    &::placeholder {
      color: var(--foreground-color);
    }
  }

  .image {
    display: none;
  }
  .content {
    .text-wrap {
      .headline {
        margin: 0;
        margin-bottom: 1em;
        color: var(--foreground-color);

        &::v-deep {
          > br:last-of-type {
            display: none;
          }
          em {
            -webkit-text-stroke-color: var(--foreground-color, $black);
          }
          p {
            color: var(--foreground-color, $black);
            margin: 0;
          }
        }
      }
      .text {
        @include p--medium;
        &::v-deep p {
          margin-top: 0 !important;
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  .newsletter {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .content {
      align-items: flex-start;

      .text-wrap {
        .headline {
          margin-bottom: 0.5em;
        }
        .text {
          padding-bottom: 0;
        }
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .newsletter {
    border-bottom: 1px solid rgba(black, 0.05);

    .newsletter__input-text {
      @include h--medium;

      font-family: $font-sans;
      margin-top: 0;
      margin-bottom: 0.55em;
    }

    .newsletter__input__icon {
      fill: var(--foreground-color);
      width: 49px;
      height: 28px;
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  .newsletter {
    // ...
  }
}

// Desktop
@media screen and (min-width: $desktop) {
  .newsletter {
    // ...
  }
}
</style>
